import React from 'react';
import 'antd/dist/reset.css';
import { FaPhone } from "react-icons/fa";
import { Affix } from 'antd';

const PhoneCall = (phoneNumber) => {  
       return ( 
        <Affix offsetTop={50}>
          <div className='sticky_phone__call'>
              <button className='button-round RedButton'>
                <a href={"tel:" + phoneNumber.phoneNumber}>
                        <FaPhone color='white'></FaPhone>
                </a>
             </button>
        </div>
   
       
        </Affix>       
    );
  };

export default PhoneCall;