import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import '../style/index.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import {  useLocation } from '@reach/router'
import PhoneCall from '../components/phone-call.component'

const Layout = ({ children, site, decorator, phoneNumber, noticeMessages }) => {
  const {pathname} = useLocation()

  // useEffect(() => {
  //   eventBus.on(noticeEvents, (messages) => {
  //     setNoticeMessages(messages);
  //     console.log("Notice message received");
  //     console.log(messages);
  //   })
  // }, [])

  const logo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid (maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)


  const menu = [
    {
      path:'/',
      title: 'Order'
    },
    {
      path: '/about',
      title: 'About'
    }
  ]

  const [showChristmasNotice, setShowChristmasNotice] = useState(false)

  useEffect(() => {
    const today = new Date();
    const start = new Date(2023, 11, 22); // Month is 0-indexed, so 11 is December
    const end = new Date(2024, 0, 1); // January 1st of the next year
  
    if (today >= start && today < end) {
      setShowChristmasNotice(true);
    }
  }, []);


  return (
    <div>
      <Helmet title="Banhmi Bites" />
      <div className="SiteContainer">
        <div className="Header">
          <div className="Wrap" >
            <div className="Header__body">
              <h1 className="Header__title">
                <Link data-text={site.siteMetadata.siteName} to="/">
                  <Img fluid={logo.file.childImageSharp.fluid} alt={site.siteMetadata.siteName}/>
                </Link>
              </h1>
              <div className="Header__menu">
                {menu.map(item => {
                  const isActive = pathname === item.path || pathname === `${item.path}/`
                  return (
                    <div key={item.title} className={`Header__menu__item ${isActive ? 'active' : ''}`}>
                      <div className="Header__menu__itemname">
                        <Link to={item.path}>
                          {/* <a href={item.path}> */}
                            {item.title}
                          {/* </a> */}
                        </Link>
                      </div>
                  </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="Wrap__Fluid Header__Notice">
          {
            (noticeMessages && noticeMessages.length > 0 || showChristmasNotice) &&
            <div class="Header__Notice__Body">
              <div class="alert alert-warning" role="alert">
                <h4 class="alert-heading">Notice of Trading Hours</h4>
                { showChristmasNotice &&
                <>
                  <p>Sun - Tue (24/12 - 26/12): CLOSED</p>
                  <p>Sun - Mon (31/12 - 01/01/24): CLOSED</p>
                </>
                }

                {
                  noticeMessages.map((m) => 
                    <p>{m}</p>
                  )
                }
              </div>
            </div>
          }
        </div>

        <div className="Wrap__Fluid Store__Content" >
            {children}
        </div>
        {decorator}
        <PhoneCall phoneNumber = {phoneNumber}></PhoneCall>
        <div className="line-copyright">
            <span>
              © Copyright 2023 BanhmiBites. Website by Anh @ <a href="https://flynntruong.com" target="_blank">flynntruong.com</a>.
            </span>
            <span> Some icons are from <a href="http://wishforge.games" target="_blank">wishforge.games</a> & <a href="https://fontawesome.com" target="_blank">Font Awesone</a></span>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
} 

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout

