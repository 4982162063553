export const getContentBodyByKey = (data, key) => {
    try {
        console.log(key)
        return data.contents.edges.find(c => c.node.key === key).node.body
    }
    catch (err) {
        return null;
    }
}

export const getEnableCheckoutSetting = (data) => {
    return (data.settings.nodes.find(s => s.name === 'Enable Checkout') ? 
    data.settings.nodes.find(s => s.name === 'Enable Checkout').value : false) === 'true';
}

export const getSettingValueByName = (data, name) => {
    return data.settings.nodes.find(s => s.name === name).value;
}

export const getStoreAvailabilitySetting = (data) => {
    return data.storeAvailabilitySetting.edges[0].node
}